<template>
  <section>
     <cosecha-urgente></cosecha-urgente>
    <screenshot name-pdf="Matriz SKU"></screenshot>
    <b-row>
      <b-col>
        <GoodTableBasic :data-table="dataTable" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import CosechaUrgente from "@/views/clients/blumar/components/CosechaUrgente";
import Screenshot from "@/views/clients/blumar/components/Screenshot";
import GoodTableBasic from "@/views/clients/blumar/components/GoodTableBasic";
export default {
  data() {
    return {
      dataTable: {
        planning: [
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "9",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "5,222",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "5",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "5,222",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "6,444",
            porciones_pieza: "12",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "0,98",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "5,222",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "5,222",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "5,222",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "6,444",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
          {
            codigo: "20807",
            descripcion: "ATLANTIC FROZEN TRIM E DPSK PREMIUM 900-1000 G CTL",
            kilos_wfe: "4,000",
            porciones_pieza: "1",
            primaria: "1",
            entero_fresco: "-",
            filete: "1",
            filete_fresco: "-",
            sellado_filete: "1",
            porcion: "-",
            sellado_porcion: "-",
            tunel_estatico: "-",
            tunel_continuo: "1",
          },
        ],
        columns: [
          {
            label: "(1) Código",
            field: "codigo",
          },
          {
            label: "(8) Descripción",
            field: "descripcion",
          },
          {
            label: "Kilos WFE/Pieza",
            field: "kilos_wfe",
          },
          {
            label: "Porciones/Pieza",
            field: "porciones_pieza",
          },
          {
            label: "Primaria",
            field: "primaria",
          },
          {
            label: "Entero fresco",
            field: "entero_fresco",
          },
          {
            label: "Filete",
            field: "filete",
          },
          {
            label: "Filete Fresco",
            field: "filete_fresco",
          },
          {
            label: "Sellado Filete - MP Congelado",
            field: "sellado_filete",
          },
          {
            label: "Porción",
            field: "porcion",
          },
          {
            label: "Sellado Porción Congelada Individual",
            field: "sellado_porcion",
          },
          {
            label: "Túnel Estático",
            field: "tunel_estatico",
          },
          {
            label: "Túnel Continuo",
            field: "tunel_continuo",
          },
        ],
        verDetalle: true, // para mostrar o no el ver detalle en el componente GoodTable
      },
    };
  },
  components: {
    BRow,
    BCol,
    GoodTableBasic,
    Screenshot,
    CosechaUrgente
  },
};
</script>

<style>
span{
  font-size: 14px;
}
</style>
